.fade-in {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 2s ease, transform 2s ease;
}

.fade-in.visible {
    opacity: 1;
    transform: translateY(0);
}
