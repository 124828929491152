.app-background {
    position: relative;
    min-height: 100vh; 
    display: flex;
    flex-direction: column; 
}

.background-video {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    z-index: -1;
}

.app-content {
    flex-grow: 1; 
    position: relative; 
    z-index: 1; 
}

* {
    cursor: url('./cursor.svg'), auto; 
}

button,
a,
.MuiButton-root,
.MuiIconButton-root,
.MuiCarousel-button,
.MuiCarousel-indicator, .MuiButtonBase-root {
    cursor: url('./cursor.svg'), auto; 
}
